.footer {
    background-color: #0a2a5b; /* Navy blue background */
    color: white;
    padding: 20px 0;
    text-align: center;
    width: 100%;
}

.footer-container {
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Footer logo styling */
.footer-logo {
    margin-bottom: 20px; /* Adds space between the logo and text below */
}

.footer-logo-image {
    max-width: 50%; /* Ensures the logo is responsive */
    height: auto;
}

/* Footer bottom section with text on the left and links on the right */
.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
}

/* Footer text styling */
.footer-text {
    margin: 0;
    font-size: 14px;
    flex-grow: 1;
    text-align: left;
}

/* Footer links section, aligned to the right */
.footer-links {
    display: flex;
    gap: 20px;
    flex-wrap: wrap; /* Wraps links to a new line if they don’t fit */
}

/* Individual footer link styling */
.footer-link {
    color: #1ed1a1; /* Teal color for links */
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
}

/* Hover effect for footer links */
.footer-link:hover {
    color: #13a482; /* Darker teal color on hover */
}

/* Responsive design for mobile screens */
@media screen and (max-width: 768px) {
    .footer-bottom {
        flex-direction: column;
        text-align: center;
        gap: 10px;
    }

    .footer-text {
        margin-bottom: 10px;
        text-align: center;
    }

    .footer-links {
        justify-content: center;
        gap: 10px;
        margin-top: 10px;
    }
}

/* Even smaller screen adjustments (mobile-first design) */
@media screen and (max-width: 480px) {
    .footer-logo-image {
        max-width: 80%; /* Adjust logo size on smaller screens */
    }
    .footer-text
    {

    }
     .footer-link {
        font-size: 13px; /* Smaller font for better readability on small screens */
        margin-left: 0px;
    }
}
