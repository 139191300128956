
.banner-section {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust height as needed */
    overflow: hidden;
    background-color: 'rgba(0, 0, 0, 0.6)';
}

.banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../images/banner01.jpg') no-repeat center center;
    background-size: cover;
    z-index: -1; /* Ensures the background image is behind the content */
    background-color: 'rgba(0, 0, 0, 0.2)';
}

.banner-bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    z-index: 1; /* Ensures the overlay is above the image */
}

.container {
    position: relative;
    z-index: 1; /* Ensures the content is above the background image */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.banner-content {
    text-align: center;
    color: white; /* Adjust text color as needed for visibility */
}

.title {
    font-size: 4rem; /* Adjust font size as needed */
    line-height: 1.5;
    margin-top: 0px; /* Remove top margin to eliminate extra space */
}

.highlight {
    color: #66cc99; /* Adjust highlight color as needed */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .banner-section {
        padding: 60px 0;
    }

    .banner-content .title {
        font-size: 32px;
        margin-top: 0px; /* Ensure no top margin in mobile view */
    }

    .banner-content .title span.d-block {
        display: block;
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .banner-section {
        padding: 0px 0;
    }

    .banner-content .title {
        font-size: 28px;
        margin-top: -100px; /* Ensure no top margin in mobile view */
    }

    .banner-content .title span.d-block {
        font-size: 16px;
    }
}

