.navbar {
  background-color: #0a1131; /* Adjust the background color as needed */
  padding: 10px 20px; /* Adjust padding as needed */
  display: flex;
  justify-content: center; /* Ensure the navbar content is centered */
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for styling */
}

.navbar-container {
  display: flex;
  justify-content: center; /* Center the logo horizontally */
  align-items: center;
  width: 100%;
  position: relative;
}

.logo img {
  height: 100px; /* Adjust the size of the logo as needed */
}

.navbar-heading h1 {
  margin: 0;
  font-size: 2rem; /* Adjust the font size as needed */
  color: rgb(217, 134, 134); /* Adjust text color as needed */
  white-space: nowrap; /* Prevents text from wrapping to a new line */
}

@media (max-width: 768px) {
  .navbar-heading h1 {
      font-size: 1.5rem; /* Adjust the font size for smaller screens */
  }

  .logo img {
      height: 70px; /* Adjust logo size for smaller screens */
  }
}
