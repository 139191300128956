.newslater-section {
    padding: 60px 0;
    background-color:#0a1131;
    color: white;
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  
  .newslater-container {
    background: url('../images/newslater-bg01.jpg') no-repeat center center;
    background-size: cover;
    border-radius: 20px;
    padding: 40px 20px;
    width: 100%;
    max-width: 1000px;
    position: relative;
    z-index: 1;
  }
  
  .newslater-wrapper {
    position: relative;
    z-index: 2;
  }
  
  .cate {
    color: #66cc99;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: white;
  }
  
  .newslater-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .newslater-form input {
    padding: 12px 15px;
    border-radius: 30px;
    border: none;
    outline: none;
    margin-right: 10px;
    width: 300px;
    max-width: 100%;
  }
  
  .newslater-form button {
    background: linear-gradient(to right, #ff5858, #6d00ff);
    border: none;
    color: white;
    padding: 12px 30px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .newslater-form button:hover {
    background: linear-gradient(to right, #6d00ff, #ff5858);
  }
  
  p {
    margin-top: 20px;
    font-size: 0.9rem;
    color: #cccccc;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .newslater-container {
      padding: 30px 15px;
    }
  
    .cate {
      font-size: 1.25rem;
    }
  
    .title {
      font-size: 1.5rem;
    }
  
    .newslater-form input {
      margin-bottom: 10px;
      width: 100%;
    }
  
    .newslater-form button {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .newslater-container {
      padding: 20px 10px;
    }
  
    .title {
      font-size: 1.25rem;
    }
  
    .cate {
      font-size: 1.2rem;
    }
  
    .newslater-form input {
      font-size: 0.9rem;
    }
  
    .newslater-form button {
      font-size: 0.9rem;
    }
  }
  